import { Injectable } from "@angular/core";
import { getEnvironment } from "../environment/environment";

@Injectable({
    providedIn: 'root',
})
// Data WebApp Site
export class UserModel {

    env = getEnvironment();
    
    title:                  string = this.env.title;
    redirect_url:           string | null = null;
    menu:                   Array<any> = [];
    organizations:          Array<any> = [];
    current_organization:   any;

    constructor() {
    }

    setRedirectUrl(value:any){
        this.redirect_url = value;
        localStorage.setItem('redirect_url', this.redirect_url || '');
    }

    getRedirectUrl(){
        this.redirect_url = localStorage.getItem('redirect_url') || null;
        return this.redirect_url;
    }

    setTitle(value:any){
        this.title = value;
        localStorage.setItem('title', this.title);
    }
    getTitle(){
        this.title = localStorage.getItem('title') || this.env.title;
        return this.title;
    }

    setMenu(value:any){
        this.menu = value;
        localStorage.setItem('menu', JSON.stringify(this.menu));
    }
    getMenu(){
        this.menu = JSON.parse(localStorage.getItem('menu') || '') || null;
        return this.menu;
    }

    setOrganizations(value:any){
        this.organizations = value;
        localStorage.setItem('organizations', JSON.stringify(this.organizations));
    }
    getOrganizations(){
        this.organizations = JSON.parse(localStorage.getItem('organizations') || '') || null;
        return this.organizations;
    }

    setCurrentOrganization(value: any){
        this.current_organization = value;
        localStorage.setItem('current_organization', JSON.stringify(this.current_organization));
    }
    getCurrentOrganization(){
        this.current_organization = JSON.parse(localStorage.getItem('current_organization') || '') || null;
        return this.current_organization;
    }

    uploadOrganizationUser(current_organization: any, organizations: any){
        this.setOrganizations(organizations);
        this.setCurrentOrganization(current_organization);
    }
}