import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class CustomSubjectsService {
    private _changeProfile = new BehaviorSubject<boolean>(false);
	changeProfile$ = this._changeProfile.asObservable();

	get changeProfileValue(): boolean {
		return this._changeProfile.value;
	}
	set changeProfileValue(data: boolean) {
		this._changeProfile.next(data);
	}
}