 
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
 
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastr: ToastrService) {}
 
  success(title: string, message: string, time?: any, closeButton?: boolean, progress?: boolean ) {
    time = time ? time : 6000;
    closeButton = closeButton == false ? closeButton : true;
    progress = progress == false ? progress : true;
    this.toastr.success(message, title, {
      timeOut: time,
      closeButton: closeButton,
      extendedTimeOut: 2000,
      // Icon IN toastClass
      toastClass: 'circle-check',
      progressBar: progress,
    });
  }
 
  error(title: string, message: string, time?: any, closeButton?: boolean, progress?: boolean ) {
    time = time ? time : 6000;
    closeButton = closeButton == false ? closeButton : true;
    progress = progress == false ? progress : true;
    this.toastr.error(message, title, {
      timeOut: time,
      closeButton: closeButton,
      extendedTimeOut: 2000,
      // Icon IN toastClass
      toastClass: 'circle-exclamation',
      progressBar: progress,
    });
  }
 
  info(title: string, message: string, time?: any, closeButton?: boolean, progress?: boolean ) {
    time = time ? time : 6000;
    closeButton = closeButton == false ? closeButton : true;
    progress = progress == false ? progress : true;
    this.toastr.info(message, title, {
      timeOut: time,
      closeButton: closeButton,
      extendedTimeOut: 2000,
      // Icon IN toastClass
      toastClass: 'circle-info',
      progressBar: progress,
    });
  }
 
  warning(title: string, message: string, time?: any, closeButton?: boolean, progress?: boolean ) {
    time = time ? time : 6000;
    closeButton = closeButton == false ? closeButton : true;
    progress = progress == false ? progress : true;
    this.toastr.warning(message, title, {
      timeOut: time,
      closeButton: closeButton,
      extendedTimeOut: 2000,
      // Icon IN toastClass
      toastClass: 'triangle-exclamation',
      progressBar: progress,
    });
  }
}