import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  standalone: true,
})
export class FilterPipe implements PipeTransform {
    transform(list: any[], searchValue: string, filterProperty?: string): any[] {
      if (!searchValue) {
        return list;
      } else {
        if(filterProperty) {
          return list.filter(item => item[filterProperty].toLowerCase().includes(searchValue.toLowerCase()));
        } else {
          return list.filter(item => item.toLowerCase().includes(searchValue.toLowerCase()));
        }
      }
    }
  }