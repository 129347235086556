import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appSearch]',
  standalone: true,
})
export class SearchDirective {
  @Output() appSearch = new EventEmitter<string>();

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    this.appSearch.emit((event.target as HTMLInputElement).value);
  }
}